import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

export class ClientError extends Error {
  constructor(error: Error) {
    super(error.message);
    this.name = 'ClientError';
    this.message = `${error.name}: ${error.message}`;
  }
}

export interface AxiosCustomError {
  message: string;
}

export class ApiError<T extends AxiosCustomError = AxiosCustomError>
  extends Error
  implements AxiosError<T>
{
  config: AxiosRequestConfig;
  code?: string;
  request?: any;
  response?: AxiosResponse<T>;
  isAxiosError: boolean;
  toJSON: () => any;

  constructor(error: AxiosError, message?: string) {
    super(message ?? error.message);

    this.name = 'ApiError';
    this.stack = error.stack;

    this.config = error.config;
    this.code = error.code;
    this.request = error.request;
    this.response = error.response;
    this.isAxiosError = error.isAxiosError;
    this.message = error.response?.data.message || '';
    this.toJSON = error.toJSON;
  }
}

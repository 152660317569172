import * as Sentry from '@sentry/nextjs';
import { ErrorEvent } from '@sentry/types';
import axios from 'axios';
import { ApiError, ClientError } from './errors';

export const sendErrorToSentry = (error: ApiError | Error) => {
  const err = error instanceof ApiError ? error : new ClientError(error);

  Sentry.captureException(err, scope => {
    scope.setContext('error', { name: err.name, message: err.message });
    return scope;
  });
};

type ErrorLogBodyType = {
  USER_INFO: string | null;
  DOMAIN: string;
  MESSAGE?: string;
  RENDER_TYPE: 'CLIENT_SIDE' | 'SERVER_SIDE' | 'EDGE';
  ENVIRONMENT?: string;
  URL?: string;
  REQUEST_URL?: string | null;
  USER_ACTION?: string | null;
  AGENT?: string;
  EVENT_ID?: string;
};

export async function sendError(
  renderType: 'SERVER_SIDE' | 'CLIENT_SIDE' | 'EDGE',
  event: ErrorEvent,
  hint?: Sentry.EventHint & { originalException: ApiError | Error },
) {
  const clicks = event?.breadcrumbs?.filter(breadcrumb => breadcrumb.category === 'ui.click');
  const lastAction = clicks ? clicks[clicks.length - 1] : null;
  const userInfo = typeof window !== 'undefined' ? window?.userInfo : null;
  const log: ErrorLogBodyType = {
    USER_INFO: userInfo ? `ID:${userInfo.id} NAME:${userInfo.profile?.name}` : 'NOT_LOGGED_IN',
    DOMAIN: '미니인턴',
    MESSAGE: `${event.contexts?.error?.message ?? `Uncaught ${hint?.originalException}`}`,
    RENDER_TYPE: renderType,
    URL: `${event.request?.url}`,
    REQUEST_URL:
      hint?.originalException instanceof ApiError
        ? `[${hint.originalException.config.method}]${hint.originalException.config.url}`
        : null,
    AGENT: event.request?.headers?.['User-Agent'],
    USER_ACTION: lastAction && `${lastAction?.category} - ${lastAction?.message}`,
    EVENT_ID: event.event_id,
  };

  try {
    axios.post(process.env.CLIENT_URL + '/api/telegram/error', { log });
  } catch (err) {
    console.error(err);
  }

  return event;
}
